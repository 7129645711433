package earth.worldwind

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.AssetResource
import dev.icerock.moko.resources.FileResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.ResourcePlatformDetails
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "3645b875de3c99a31a56b4837a95d359"

  public actual object images : ResourceContainer<ImageResource> {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    public actual val sun_texture: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/sun_texture.png\")") as String, fileName = "sun_texture.png")

    public actual val worldwind_worldtopobathy2004053: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/worldwind_worldtopobathy2004053.png\")") as String, fileName =
        "worldwind_worldtopobathy2004053.png")

    public actual val dnb_land_ocean_ice_2012: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/dnb_land_ocean_ice_2012.png\")") as String, fileName =
        "dnb_land_ocean_ice_2012.png")

    public actual override fun values(): List<ImageResource> = listOf(sun_texture,
        worldwind_worldtopobathy2004053, dnb_land_ocean_ice_2012)
  }

  public actual object files : ResourceContainer<FileResource> {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    public actual val stars_json: FileResource = FileResource(fileUrl =
        js("require(\"./files/stars.json\")") as String)

    public actual override fun values(): List<FileResource> = listOf(stars_json)
  }

  public actual object assets : ResourceContainer<AssetResource> {
    public actual override val __platformDetails: ResourcePlatformDetails =
        ResourcePlatformDetails()

    public actual val EGM96_dat: AssetResource = AssetResource(originalPath =
        js("require(\"./assets/EGM96.dat\")") as String, rawPath = "EGM96.dat")

    public actual override fun values(): List<AssetResource> = listOf(EGM96_dat)
  }
}
